.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #2a292c;
}

.logo {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.email-input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 80%;
  max-width: 300px;
  margin-bottom: 10px;
}

.entrar-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
